import { simpleFormHandler } from '@frontend/jetlend-core/src/ducks/form';
import {
    all,
    put,
    select,
} from 'redux-saga/effects';
import { combineReducers } from 'redux';
import { objectHandler } from '@frontend/jetlend-core/src/ducks/object';
import { simpleSagaHandler } from '@frontend/jetlend-core/src/ducks/saga';
import {
    isEmptyValue,
    requirePhone,
    required,
} from '@frontend/jetlend-core/src/validators';
import { ApiDataResponse } from '@frontend/jetlend-core/src/models/api';
import { addToastError } from '@frontend/jetlend-core/src/ducks/toasts';
import { isMobile } from 'mobile-device-detect';
import {
    signDataWithSmsSaga,
    SMS_ID__SKIP,
} from './sms';
import { sendEvent } from './analytics';
import {
    CLIENT_TYPE_CABINET_URLS,
    ClientType,
    CommonStage,
    CLIENT_TYPE_LOGIN_URLS,
} from '@app/models/common/common';
import {
    ILoginFormValues,
    ILoginResultApiModel,
    ILoginState,
    LoginDeviceType,
} from '@app/models/common/login';
import {
    apiPostLogin,
    apiPostUserLogout,
} from '@app/services/client/common/loginService';
import { apiPostExternalUserLogin } from '@app/services/client/common/oauthService';
import { IExternalLoginValues } from '@app/models/common/oauth';

export const PREFIX = 'common_login';

export const commonLoginStateHandler = objectHandler<ILoginState>(
    PREFIX, 'state'
);

type StartLoginSagaEvent = {
    value: {
        clientType: ClientType;
        stage: CommonStage;
        step?: CommonStage;
        phone?: string;
        password?: string;
    };
};

function refreshPageSaga() {
    window.location.reload();
}

/**
 * Form handler для работы с формой вариантов сторонней авторизации.
 */
export const loginViaExternalAuthFormHandler = simpleFormHandler<IExternalLoginValues>(PREFIX, 'external_auth_form', undefined, {
    apiMethod: apiPostExternalUserLogin,
    *onBeforeSubmit (data: Omit<IExternalLoginValues, 'clientType'>) {
        const payload = yield select(commonLoginStateHandler.selector);
        const { clientType }: ILoginState = payload;

        if (clientType) {
            yield put(sendEvent(`${clientType}--external-login--${data.auth_variant}`));
        }

        const output: IExternalLoginValues = {
            ...data,
            clientType,
            return_uri: CLIENT_TYPE_LOGIN_URLS[clientType],
            device_type: isMobile
                ? LoginDeviceType.MobileWeb
                : LoginDeviceType.DesktopWeb,
        };

        return output;
    },
    onSuccess (response: ApiDataResponse<ILoginResultApiModel>, values) {
        const redirectUri = response.data?.redirect_uri;
        const { clientType } = values;

        if (!isEmptyValue(redirectUri)) {
            document.location.href = redirectUri;
        } else {
            document.location.href = CLIENT_TYPE_CABINET_URLS[clientType];
        }
    },
    *onFormError (message) {
        yield put(addToastError(message));
    },
}, {});

const startLoginSagaHandler = simpleSagaHandler(PREFIX, 'start', function* ({ value }: StartLoginSagaEvent) {
    const {
        clientType,
        phone,
        password,
    } = value;

    // Initialize state for first screen
    yield put(commonLoginStateHandler.update({
        clientType,
        phone,
        password,
        stage: CommonStage.Login,
        redirectUri: undefined,
    }));

    // Update form for initial values if that exists
    const formValues: ILoginFormValues = {
        clientType,
        phone,
        password,
    };

    yield put(commonLoginFormHandler.formInitialize(formValues));
});

/**
 * Функция, для дополнения данных формы ссылкой,
 * на страницу с которой перешли на страницу авторизации
 * @param formValues данные формы
 * @returns переданные данные/дополненные ссылкой данные (если пользователь перешел со страницы академии)
 */
export function setRedirectUriFormValues(formValues) {
    const params = new URL(document.location).searchParams;
    const returnUri = params.get('return_uri');

    if (returnUri) {
        formValues.return_uri = returnUri;
    }

    return formValues;
}

export const commonLoginFormHandler = simpleFormHandler<ILoginFormValues>(
    PREFIX, 'login_form', {
        v2: {
            phone: [ required(), requirePhone() ],
            password: [ required() ],
        },
    }, {
        apiMethod: apiPostLogin,
        *onBeforeSubmit (values: ILoginFormValues) {
            yield put(commonLoginStateHandler.update({
                phone: values.phone,
            }));

            const {
                clientType,
                phone,
                ...payload
            } = values;

            const smsId = yield signDataWithSmsSaga(
                clientType,
                phone,
                {
                    phone,
                    ...payload,
                },
                'login',
                function* () {
                    yield put(commonLoginStateHandler.update({
                        stage: CommonStage.Sms,
                    }));
                }
            );

            if (smsId === SMS_ID__SKIP) {
                return setRedirectUriFormValues(values);
            }

            if (!smsId) {
                yield put(commonLoginStateHandler.update({
                    stage: CommonStage.Login,
                }));

                return;
            }

            yield put(commonLoginStateHandler.update({
                stage: CommonStage.Loading,
                loadingTitle: 'Авторизация',
            }));

            const output: ILoginFormValues = {
                ...values,
                sms_id: smsId,
            };

            return setRedirectUriFormValues(output);
        },
        *onSuccess (response: ApiDataResponse<ILoginResultApiModel>, values) {
            const { clientType } = values;

            const redirectUri = response.data?.redirect_uri ?? CLIENT_TYPE_CABINET_URLS[clientType];

            if (clientType) {
                yield put(sendEvent(`${clientType}--login`));
            }

            if (redirectUri) {
                yield put(commonLoginStateHandler.update({
                    redirectUri,
                }));
            }

            if (!isEmptyValue(redirectUri)) {
                document.location.href = redirectUri;
            } else {
                document.location.href = CLIENT_TYPE_CABINET_URLS[clientType];
            }
        },
        *onFormError () {
            yield put(commonLoginStateHandler.update({
                stage: CommonStage.Login,
            }));
        },
        echo: true,
    }, {}
);

export const commonLogoutUserFormHandler = simpleFormHandler(
    PREFIX, 'user_logout', {}, {
        apiMethod: apiPostUserLogout,
        onSuccess() {
            refreshPageSaga();
        },
    }, {}
);

export const changePhoneNumberSagaHandler = simpleSagaHandler(PREFIX, 'change_phone', function* () {
    yield put(commonLoginStateHandler.update({
        stage: CommonStage.Login,
    }));
    yield put(commonLoginFormHandler.formReset());
});

export const changePasswordResetStepSagaHandler = simpleSagaHandler(PREFIX, 'change_password', function* ({ value }) {
    const { stage } = value;
    yield put(commonLoginStateHandler.update({ stage }));
});

export const changePhoneNumber = () => changePhoneNumberSagaHandler.action();
export const changePasswordResetStep = (stage: CommonStage) => changePasswordResetStepSagaHandler.action({ stage });

export const startLogin = (clientType: ClientType) => startLoginSagaHandler.action({ clientType });

export const reducer = combineReducers({
    ...commonLoginFormHandler.reducerInfo,
    ...commonLoginStateHandler.reducerInfo,
    ...commonLogoutUserFormHandler.reducerInfo,
    ...loginViaExternalAuthFormHandler.reducerInfo,
});

export function* rootSaga() {
    yield all([
        ...commonLoginFormHandler.effects,
        ...startLoginSagaHandler.effects,
        ...changePhoneNumberSagaHandler.effects,
        ...changePasswordResetStepSagaHandler.effects,
        ...commonLogoutUserFormHandler.effects,
        ...loginViaExternalAuthFormHandler.effects,
    ]);
}
